@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the top */
    padding: 20px;
    background-color: #f8f8f8;
    border-top: 1px solid #e7e7e7;
    font-family: 'Raleway', 'Nunito Sans', sans-serif;
    font-size: 20px;
  }
  
  .left-part {
    padding-left: 7%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align the buttons to the top */
  }
  
  .text-buttons {
    display: flex;
    gap: 45px; /* Space between the text buttons */
    /* force the text not to break */
    white-space: nowrap;
  }
  
  .footer-link {
    text-decoration: none;
    color: #54595f;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #d55165; /* Hover effect */
  }
  
  .right-part {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 7%;
  }
  
  .social-icons {
    display: flex;
    gap: 20px; /* Space between social icons */
    padding-bottom: 20px;
  }
  
  .social-icon {
    color: #54595f;
    font-size: 1.6vw; /* Icon size */
    /* make a min font-size */
    transition: color 0.3s ease;
  }

 @media screen and (max-width: 2001px) {
  .social-icon {
    font-size: 30px; /* Icon size */
  }

  .copyright {
    padding-left: 50px;
  }
}
  
  .social-icon:hover {
    color: #d55165; /* Hover effect for icons */
  }
  
  .copyright {
    font-size: 15px; /* Smaller font size for copyright text */
    color: #54595f;
    text-align: right;
  }
  
  /* Responsive adjustments */
  /*@media only screen and (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .right-part {
      align-items: flex-start;
      margin-top: 20px;
    }
  
    .social-icons {
      margin-bottom: 10px;
    }
  
    .footer-link {
      font-size: 3.5vw;
    }
  
    .copyright {
      font-size: 2.5vw;
    }
  
    .social-icon {
      font-size: 5vw;
    }
  }*/

    /*now for the phone screen size */
    @media only screen and (max-width: 768px) {
      .footer {
        flex-direction: column;
        align-items: center;
        padding: 10px;
        text-align: center;
      }

      .left-part, .right-part {
        padding-left: 5%;
        padding-right: 5%;
      }

      .text-buttons {
        flex-direction: column;
        gap: 20px;
      }

      .social-icons {
        align-items: center;
        gap: 20px;
        padding-top: 5%;
      }

      .social-icon {
        font-size: 6vw;
      }

      .copyright {
        text-align: center;
        padding-left: 0px;
      }
    }