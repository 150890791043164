@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');


/* Header and Navbar Styling */
.header {
    background-color: white;
    padding: 10px 20px;
    border-bottom: 1px solid #eaeaea;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container {
    padding-left: 20px;
}

.logo {
    width: 150px;
    height: auto;
    display: block;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 55px;
    padding-right: 20px;
    margin: 0;
    align-items: center;
}

.nav-links a {
    text-decoration: none;
    color: #54595f;
    font-weight: 0;
    transition: color 0.3s ease;
    cursor: pointer;
}

.nav-links a:hover {
    color: #808080;
}

/* Divider Styling */
.divider {
    width: 1px;
    height: 30px;
    background-color: #eaeaea;
}

/* Dropdown Menu Styling for large screens */
.menu {
    position: relative;
}

.dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: -40px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    border-radius: 4px;
    z-index: 1000;
    list-style: none; /* Remove bullet points */
}

.menu:hover .dropdown {
    display: block;
}

.dropdown li a {
    padding: 20px 20px;
    display: block;
    text-align: right;
    color: #54595f;
    text-decoration: none;
    /* Set a minimum width for the dropdown */
    min-width: 200px;
}

.dropdown li a:hover {
    background-color: #f0f0f0;
    color: #333;
}

/* Hamburger Menu */
.hamburger {
    display: none;
    flex-direction: column;
    gap: 6.5px;
    cursor: pointer;
}

.hamburger .line {
    width: 30px;
    height: 1px;
    background-color: #333;
    border-radius: 2px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .nav-links {
        /* Hidden by default */
        display: block;
        flex-direction: column;
        text-align: right;
        position: fixed;
        top: 70px;
        right: -100%;
        width: 220px;
        background-color: white;
        padding-top: 80px; /* Adjust to match your header height */
        padding-right: 20px;
        border-radius: 4px;
        transition: right 0.3s ease-in-out;
        z-index: 1000; /* Ensure the menu is above other content */
    }

    .mobile-language-toggle {
        display: block;
    }

    .nav-links.mobile-open {
        display: block; /* Shows the nav links */
        position: absolute;
        top: 70px;
        right: 0;
        background-color: white;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        width: 220px;
        text-align: right;
        border-radius: 4px;
        z-index: 10000; /* Ensure the mobile menu is above other content */
        transform: translateX(0); /* Slides into view */
        opacity: 1; /* Make visible */
        visibility: visible; /* Make clickable */
    }

    .nav-links.mobile-open .menu {
        display: none; /* Hide the Portfolio dropdown in mobile view */
    }

    /* Portfolio items visible directly in mobile view */
    .mobile-portfolio {
        display: none; /* Hidden by default */
    }

    .mobile-portfolio.visible {
        display: block;
    }

    .nav-links.mobile-open .mobile-portfolio li {
        margin: 10px 0;
        padding: 10px 20px;
    }

    .nav-links.mobile-open li {
        margin: 10px 0;
        padding: 10px 20px;
    }

    /* Adjust line spacing between Home and Contact in mobile */
    .nav-links.mobile-open li:nth-child(1),
    .nav-links.mobile-open li:nth-child(2) {
        margin-bottom: 10px;
    }

    /* Vertical separator in mobile view */
    .divider {
        display: block;
        width: 100%;
        height: 2px;
        margin: 10px 0;
        background-color: #d3d3d3;
    }

    .hamburger {
        display: flex; /* Show the hamburger menu on mobile */
    }

    .line {
        width: 100%;
        height: 3px;
        background-color: #333;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .line.open:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .line.open:nth-child(2) {
        opacity: 0;
    }

    .line.open:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}

/* Hide mobile-portfolio on large screens */
@media (min-width: 769px) {
    .mobile-portfolio {
        display: none; /* Hidden on large screens */
    }

    .dropdown {
        list-style: none; /* Ensure no bullet points on large screens */
    }
}

/* The language dropdown */
.dropdown-language {
    display: none;
    position: absolute;
    top: 100%;
    right: -40px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    border-radius: 4px;
    z-index: 1000;
    list-style: none; /* Remove bullet points */
}

.menu:hover .dropdown-language {
    display: block;
}

.dropdown-language li a {
    padding: 20px 20px;
    display: block;
    text-align: right;
    color: #54595f;
    text-decoration: none;
}

.dropdown-language li a:hover {
    background-color: #f0f0f0;
    color: #333;
}

/* Language Toggle Button for Mobile */
.mobile-language-toggle {
    display: none; /* Hidden by default */
    color: #54595f;
    cursor: pointer;
    margin-left: 40px; /* Adds space between the language button and the hamburger */
}

/* Show the language toggle button on mobile */
@media (max-width: 768px) {
    .mobile-language-toggle {
        display: inline-block;
    }
}