@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');

.analyse-document-page {
  width: 100%;
  font-family: 'Raleway', 'Nunito Sans', sans-serif;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.segment {
  padding: 20px;
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 40px;
}

/* Slideshow Segment */
.slideshow-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 20px;
  width: 100%;
  margin-bottom: 40px;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}

.slideshow-segment h1 {
  margin-bottom: 65px;
  margin-top: 0px;
}

.segment h1 {
  text-align: left;
}

.analyse-description {
  font-size: 20px;
  color: #666;
}

.videos-container {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.video-item {
  position: relative;
}

.video-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.video-thumbnail:hover {
  transform: scale(1.05);
}

/* Responsive Styling */
@media screen and (max-width: 1220px) {
  .analyse-document-page {
    flex-direction: column;
    gap: 20px;
  }

  .analyse-title {
    font-size: 24px;
    text-align: center;
  }

  .analyse-description {
    font-size: 20px;
    text-align: center;
  }

  .slideshow-segment {
    max-width: 90%; /* Adjusts the slideshow for smaller screens */
  }
}

@media screen and (max-width: 768px) {
  .videos-container {
    grid-template-columns: 1fr;
  }

  .slideshow-segment {
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }
}
