@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');

/* General Container */
.container {
    height: auto;
    margin: 0 auto;
  }
  
  /* Row layout */
  .row {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }

  .videos-container {
    flex: 1;
    display: flex;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .video-item {
    position: relative;
  }
  
  .video-thumbnail {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  @media screen and (max-width: 1200px) {
    .videos-container {
      display: grid;
    }
    
  }
  .video-thumbnail:hover {
    transform: scale(1.05);
  }
  
  .image-container {
    flex: 1; /* Allow the container to grow but not force the size */
    max-width: 500px; /* Set a maximum width for the image container */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent overflow */
}
 
  
.image {
  width: 100%; /* Ensure the image fills the container */
  height: auto;
  object-fit: cover;
}

  
  /* Text container */
  .text-container {
    width: 80vw;
    padding: 10px;
  }
  
  .text {
    font-size: 20px;
    color: #666; /* Adapted from the color in your CSS */
    font-family: 'Raleway', 'Nunito Sans', sans-serif;
    font-weight: 100;
    padding: 1vw;
  }
  
  /* Mobile adjustments */
  @media only screen and (max-width: 768px) {
    .row {
      flex-direction: column;
      text-align: center;
    }

    .image {
      width: 80%;
      height: auto;
    }
  }
