.slideshow-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
}

.slideshow-title {
  font-size: 30px;
  color: #666;
  margin-bottom: 10px;
  font-weight: 100;
  text-align: center;
}

.slideshow-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80vw;
  width: 100%;
}

.slideshow-images {
  display: flex; /* This ensures multiple images are shown side by side */
  overflow: hidden; /* Prevents overflowing images */
  height: 100%;
  max-height: 1100px; /* Set a max height for the slideshow */
}

.slideshow-image {
  width: calc(100% / 3); /* Display 3 images at a time */
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.slideshow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Transparent background for overlay */
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 2rem;
  transition: background-color 0.3s ease;
  border-radius: 50%; /* Make buttons round */
}

.slideshow-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slideshow-prev {
  left: 10px;
}

.slideshow-next {
  right: 10px;
}

@media screen and (max-width: 768px) {
  
  .slideshow-image {
    width: calc(100% / 2); /* Show 2 images at a time on small screens */
  }
}

@media screen and (max-width: 480px) {
  
  .slideshow-image {
    width: 100%; /* Show 1 image at a time on mobile screens */
  }
}
