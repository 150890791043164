@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');

.contact-page {
    width: 100%;
    font-family: 'Raleway', 'Nunito Sans', sans-serif;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    height: 100vh;
}

.contact-info {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    text-align: center;
}

.contact-title {
    font-size: 30px;
    font-weight: 900;
    color: #333;
    margin-bottom: 20px;
}

.contact-text {
    font-size: 20px;
    color: #666;
    margin: 5px 0;
}

@media screen and (max-width: 768px) {
    .contact-info {
        padding: 15px;
    }

    .contact-title {
        font-size: 24px;
    }

    .contact-text {
        font-size: 18px;
    }
}
