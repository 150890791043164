@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');

.cookies-banner {
    font-family: 'Raleway', 'Nunito Sans', sans-serif;
    position: fixed;
    bottom: 0; /* Position the banner at the bottom */
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    padding: 20px;
    color: white;
    text-align: center;
    z-index: 1000; /* Ensure it overlays above other content */
  }
  
.link-text {
    color: orange;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .cookies-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .cookies-buttons button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #d55165;
    color: white;
    transition: background-color 0.3s ease;
    font-size: 20px;
    width: 150px;
  }
  
  .cookies-buttons button:hover {
    background-color: rgb(255, 16, 52);
  }
  