@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');

.repair-restore-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-family: 'Raleway', sans-serif;
    padding: 0px;
}

.segment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    font-family: 'Raleway', sans-serif;
}

.segment-image {
    flex: 0 0 45%;
    width: 65%;
    height: 60%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover !important;
}

.segment-content {
    flex: 1;
    text-align: left;
    padding: 20px;
    font-family: 'Raleway', sans-serif;
}

.segment h1 {
    text-align: left;
}

.segment-text {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    font-family: 'Raleway', sans-serif;
}

/* Media queries for responsiveness */
@media (max-width: 1100px) {
    .segment {
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }

    .segment-image {
        max-width: 100%; /* Make sure the image does not stretch beyond container width */
        height: auto; /* Keep the aspect ratio by allowing height to adjust */
        object-fit: cover; /* Ensure the image maintains its aspect ratio */
    }

    .segment-content {
        padding: 10px;
    }

    .segment-title {
        text-align: center;
        font-family: 'Raleway', sans-serif;
    }

    .segment-text {
        text-align: center;
        font-family: 'Raleway', sans-serif;
        padding-left: 10vw;
        padding-right: 10vw;
    }
}