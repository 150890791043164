@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');

.buy-rent-page {
  width: 100%;
  font-family: 'Raleway', 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f9f9f9;
  padding: 40px 0;
}

.buy-rent-content {
  text-align: left;
  border-radius: 10px;
  line-height: 1.6;
  margin-left: 30px;
  margin-right: 30px;
}

.rent-text, .verkauf-text {
  font-size: 18px;
  color: #666;
  white-space: pre-wrap;
  margin-bottom: 20px;
}

/* Center the button in its row */
.contact-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 600;
  color: #666;
  background-color: rgba(255, 166, 0, 0.49);
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  width: 130px;
}

.contact-button:hover {
  background-color: rgba(255, 166, 0, 0.79);
}

/* Verkauf section styling */
.verkauf-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.verkauf-item {
  text-align: center;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.verkauf-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.verkauf-item:hover .verkauf-thumbnail {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .buy-rent-content {
    padding: 20px;
  }

  .buy-rent-title, .verkauf-title {
    font-size: 28px;
  }

  .rent-text, .verkauf-text {
    font-size: 16px;
  }

  .verkauf-container {
    grid-template-columns: 1fr;
  }
}
