.privacy-notice-container {
    padding: 20px;
    font-family: 'Nunito Sans', sans-serif;
    color: #54595f;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .privacy-header {
    font-size: 2.5vw;
    font-weight: 900;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-subheader {
    font-size: 1.5vw;
    align-content: center;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-text {
    font-size: 1.5vw;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  @media only screen and (max-width: 768px) {
    .privacy-header {
      font-size: 5vw;
    }
  
    .privacy-subheader {
      font-size: 4vw;
    }
  
    .privacy-text {
      font-size: 3.5vw;
    }
  }
  