@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');


.landing-image {
  font-family: 'Raleway', 'Nunito Sans', sans-serif;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.span {
  /* set no underline for the text */
  text-decoration: none;
}

/* Gradient Overlay */
.landing-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 139, 0.46) 0%, rgba(0, 0, 139, 0) 100%);
  pointer-events: none;
}

/* Text Overlay */
.text-overlay {
  position: absolute;
  top: 40%;
  left: 3%;
  transform: translateY(-50%);
  color: white;
  text-align: left;
}

.handwerk-text {
  display: inline-block;
  color: orange;
  font-size: 6vw; /* Smoothly scale with viewport width */
  border: 0.7vw solid orange;
  padding: 1.5vw;
  font-weight: 900;
  border-radius: 15px;
}

.trifft-text {
  display: block;
  color: white;
  font-size: 4.8vw; /* Smoothly scale with viewport width */
  margin-left: 2.5vw;
  font-weight: 900;
}

/* MODERNE Text Overlay */
.moderne-text-overlay {
  position: absolute;
  top: 48%;
  right: 3%;
  transform: translateY(-50%);
  text-align: right;
}

.moderne-text {
  display: inline-block;
  border-radius: 15px;
  color: #d55165;
  font-size: 6vw; /* Smoothly scale with viewport width */
  border: 0.7vw solid #d55165;
  padding: 1.5vw;
  font-weight: 900;
}

/* Button Container */
.button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;
}

.button {
  background-color: rgba(255, 255, 255, 0.4); /* Semi-transparent white */
  color: white;
  flex-grow: 1;
  height: 3.5vw; /* Use vw for smooth height transitions */
  box-sizing: border-box;
  border: 0px solid #808080;
  border-radius: 0px;
  text-align: center;
  font-size: 20px; /* Use vw for smooth font size transitions */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

@media screen and (min-width: 2000px) {
  .button {
    font-size: 25px;
    height: 2.5vw;
  }
  
}


.button span {
  display: block;
}

/* Hover Effects for Each Button */
.button:nth-child(2):hover {
  background-color: orange;
  color: white;
}

.button:nth-child(1):hover,
.button:nth-child(3):hover {
  background-color: #d55165;
  color: white;
}

/* Media Queries for Responsive Design */

/* Adjustments for Smaller Screens */
@media only screen and (max-width: 768px) {
  .handwerk-text {
    font-size: 7.5vw;
  }

  .trifft-text {
    font-size: 6.5vw;
  }

  .moderne-text {
    font-size: 7.5vw;
  }

  .button {
    font-size: 3vw; /* Font size slightly smaller on smaller screens */
    /*height: 5vw;*/ /* Slightly increase button height */
  }
}

/* Adjustments for Mobile Phones */
@media only screen and (max-width: 768px) {
  /* Stack the text vertically when the screen is too small */
  .text-overlay,
  .moderne-text-overlay {
    position: relative;
    text-align: center;
    transform: none;
    top: auto;
    right: auto;
    left: 0;
  }

  .handwerk-text,
  .trifft-text,
  .moderne-text {
    font-size: 8.5vw; /* Further scale up the font size */
    display: block;
    margin: 0 auto;
    padding: 15px;
  }

  .text-overlay {
    margin-bottom: 10px;
  }

  .button {
    font-size: 3.5vw;
    height: 10vw; /* Increase button height for mobile */
  }

  /* Center all text in the middle of the screen */
  .landing-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  /* Adjust spacing between text blocks */
  .handwerk-text {
    margin-bottom: 15px;
  }

  .trifft-text {
    margin-bottom: 0px;
  }

  .moderne-text {
    margin-top: 0px;
  }

  /* Hide the buttons when the screen is too small */
  .button-container {
    display: none;
  }
}
