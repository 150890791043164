@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;900&display=swap');

body {
    font-family: 'Raleway', 'Nunito Sans', sans-serif;
    font-size: 20px;
}

h1 {
    font-size: 35px;
    font-weight: 30;
    color: #54595F;
    margin-bottom: 10px;
    text-align: center;
}

@media (max-width: 768px) {
    body {
        font-size: 18px;
    }
}